<template>
  <div>
    <h4 class="card-label border-bottom mb-3">Allergens</h4>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="form-group">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <b-button
              v-if="permission.is_add == 1"
              @click="addAllergen"
              class="btn custom-btn-style"
              v-b-modal.modal-standard
              variant=":class"
              >Add Allergen
            </b-button>
            <b-modal
              hide-footer
              id="modal-standard"
              :title="allergen_title"
              title-class="font-18"
              ref="modal"
              @show="resetModal"
              @hidden="resetModal"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="form-group">
                  <label>Select Item</label>
                  <div>
                    <select
                      v-model="menu_item_uuid"
                      required
                      class="form-control-role input-style"
                    >
                      <option disabled value="">Select Item</option>
                      <option
                        v-for="item in items"
                        :value="item.uuid"
                        v-bind:key="item.uuid"
                        :title="item.item_name"
                      >
                        {{ item.item_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <b-form-group
                  label="Name"
                  label-for="name-input"
                  invalid-feedback="Name is required"
                >
                  <b-form-input
                    id="name-input"
                    v-model="allergen_name"
                    class="input-style"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Description" label-for="description-input">
                  <b-form-input
                    id="description-input"
                    v-model="description"
                    class="input-style"
                  ></b-form-input>
                </b-form-group>
                <div class="m-t-15">
                  <b-button
                    type="button"
                    @click="handleOk"
                    class="mt-3 btn-orange pull-right"
                    :disabled="isFormValid"
                  >
                    Add
                  </b-button>
                </div>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-sm-12 col-md-3">
        <div class="dataTables_length">
          <label class="d-inline-flex align-items-center mb-0">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-9">
        <div class="dataTables_filter filter-dataTables text-md-right">
          <label class="d-inline-flex align-items-center mb-0">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <div class="ml-2">
            <button class="btn print excel-btn" @click="excelExport()">
              Export to Excel
            </button>
            <button class="btn print" @click="csvExport(csvData)">
              Export to CSV
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End search -->
    <div class="card">
      <div class="card-body p-0">
        <div class="row mb-md-2"></div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            :items="allergens"
            :fields="allergen_fields"
            responsive="true"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(action)="allergens">
              <div class="position-relative">
                <div class="table-actions">
                  <a
                    v-if="permission.is_edit == 1"
                    href="javascript:void(0)"
                    class="success-icon"
                    @click="editModal(allergens)"
                    v-b-modal.modal-standard
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                  <a
                    v-if="permission.is_deleted == 1"
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteAllergen(allergens)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
import XLSX from "xlsx";
import Swal from "sweetalert2";
import helpers from "../../helpers";
import axios from "axios";

export default {
  name: "Allergens",
  data() {
    return {
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      loader: false,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: true,
      allergens: [],
      restaurantUuid: JSON.parse(localStorage.getItem("restaurantDetails"))
        .uuid,
      allergen_fields: [
        { key: "menu_item_name", label: "item", sortable: true },
        { key: "allergen_name", label: "Allergen", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "action", label: "Actions", sortable: true }
      ],
      allergen_name: "",
      description: "",
      menu_item_uuid: "",
      allergen_uuid: "",
      items: [],
      allergen_title: "",
      DropDownMenu: false,
      id: "",
      is_edit: false
    };
  },
  components: {},
  computed: {
    rows() {
      return this.allergens.length;
    },
    csvData() {
      return this.allergens.map(item => ({
        ...item
      }));
    },
    isFormValid() {
      return !(this.allergen_name !== "" && this.menu_item_uuid !== "");
    }
  },

  methods: {
    getItems() {
      axios
        .get(
          this.$path +
            "/admin/allergens/menu/items/restaurant/" +
            this.restaurantUuid
        )
        .then(response => {
          this.items = response.data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    clickDots(id) {
      this.id = id;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    editModal(allergen) {
      this.allergen_uuid = allergen.item.uuid;
      this.allergen_name = allergen.item.allergen_name;
      this.description = allergen.item.description;
      this.menu_item_uuid = allergen.item.menu_item_uuid;
      this.is_edit = true;
      this.allergen_title = "Edit Allergen";
    },
    addAllergen() {
      this.allergen_name = "";
      this.description = "";
      this.menu_item_uuid = "";
      this.is_edit = false;
      this.allergen_title = "Add Allergen";
    },
    resetModal() {},
    handleOk() {
      this.loader = true;
      let obj = {
        allergen_name: this.allergen_name,
        description: this.description,
        menu_item_uuid: this.menu_item_uuid
      };
      this.is_edit ? this.updateAllergensApi(obj) : this.addAllergensApi(obj);
    },
    addAllergensApi(obj) {
      ApiService.post(this.$path + "/admin/allergens", obj)
        .then(response => {
          this.allergens.unshift(response.data.data);
          this.$bvModal.hide("modal-standard");
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateAllergensApi(obj) {
      ApiService.patch(
        this.$path + "/admin/allergens/" + this.allergen_uuid,
        obj
      )
        .then(response => {
          let index = this.allergens.findIndex(
            x => x.uuid === response.data.data.uuid
          );
          if (index !== -1) {
            this.allergens[index].allergen_name =
              response.data.data.allergen_name;
            this.allergens[index].description = response.data.data.description;
            this.allergens[index].menu_item_uuid =
              response.data.data.menu_item_uuid;
            this.allergens[index].menu_item_name =
              response.data.data.menu_item_name;
          }
          this.$bvModal.hide("modal-standard");
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getAllergens() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/allergens")
        .then(response => {
          this.allergens = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    deleteAllergen(allergens) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          ApiService.delete(
            this.$path + "/admin/allergens/" + allergens.item.uuid
          )
            .then(response => {
              helpers.successMessages(this, response.data.message);
              let index = this.allergens.findIndex(
                x => x.uuid === response.data.data.uuid
              );
              if (index !== -1) {
                this.allergens.splice(index, 1);
              }
              this.loader = false;
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
        this.loader = false;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Allergens.csv");
      link.click();
    },
    excelExport() {
      const data = XLSX.utils.json_to_sheet(this.allergens);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Allergens.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantUuid = this.restaurantData.uuid;
    this.getAllergens();
    this.getItems();
    this.totalRows = this.allergens.length;
    let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    this.permission = {
      is_add: permission.is_add,
      is_deleted: permission.is_deleted,
      is_edit: permission.is_edit,
      web_access_enabled: permission.web_access_enabled
    };
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>
